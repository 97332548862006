import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';

import { useLoomEntryPointVariant } from '@confluence/loom-utils';

import { LoomVideoIcon } from './VideoIcon';

const buttonSpacerStyles = xcss({
	minWidth: '30px',
});

export const LoomPageHeaderPlaceholder = ({ isCircle }: { isCircle?: boolean }) => {
	const { entryPointVariant } = useLoomEntryPointVariant();

	if (entryPointVariant === 'DISABLED') {
		return null;
	} else {
		return (
			<Box xcss={buttonSpacerStyles}>
				<IconButton
					appearance="subtle"
					shape={isCircle ? 'circle' : 'default'}
					icon={LoomVideoIcon}
					testId="page-header-loom-entry-point-loading-icon"
					data-vc="loom-button"
					isDisabled
					label=""
				/>
			</Box>
		);
	}
};
